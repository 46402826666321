<template>
  <el-row class="dt-pagination xa-bg-white" type="flex" align="middle">
    <el-pagination
      class="xa-mgr-16"
      @size-change="$emit('sizechange', $event)"
      @current-change="$emit('curchange', $event)"
      :current-page="curPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
    <el-tooltip
      class="item"
      effect="dark"
      :content="$t('Refresh')"
      placement="top"
    >
      <el-button
        plain
        @click="$emit('refresh')"
        type="primary"
        size="mini"
        icon="el-icon-refresh"
      ></el-button>
    </el-tooltip>
  </el-row>
</template>
<script>
export default {
  props: {
    curPage: null,
    pageSizes: null,
    pageSize: null,
    total: null,
    loading: null
  }
}
</script>
<style scoped>
.dt-pagination {
  height: 40px;
  flex-shrink: 0;
}
</style>
