<template>
  <div class="xa-container form-wrap">
    <AppLineLoading v-show="isLoading" />
    <XaForm
      v-if="items.length"
      :items="items"
      :values="variables"
      @finish="handleFinish"
    />
    <div style="height:100px"></div>
  </div>
</template>
<script>
import XaForm from '@/components/xa-form/form/index'
export default {
  configPromise: null,
  components: {
    XaForm
  },
  props: {
    configPromise: {
      type: Promise
    }
  },
  data() {
    return {
      isLoading: true,
      items: [],
      variables: {}
    }
  },
  methods: {
    handleFinish() {
      this.$emit('close')
      this.$emit('refresh')
    },
    getViewConfig() {
      this.isLoading = true
      const configPromise =
        this.configPromise ||
        this.$options.configPromise ||
        Promise.reject({ message: 'DataForm没有配置configPromise' })
      return configPromise
        .catch(error => {
          this.$message({
            message: error.message || error.msg,
            type: 'error'
          })
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    async init() {
      const config = await this.getViewConfig()
      if (config) {
        const { items, variables, doc_title } = config
        if (doc_title) this.$emit('title', doc_title)
        this.items = items
        this.variables = variables
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="scss">
.form-wrap {
  position: relative;
  min-height: 16px;
}
.form-mini {
  padding: 0 16px;
  [class*='_form_com_'] {
    .xa-form__label {
      width: 100%;
      max-width: 100%;
      text-align: left;

      & + div {
        width: 100%;
      }
    }
  }
}
</style>
