/* <template>
        <FilterBarHead :isLoading="isLoading" :title="title" :filterConfig="filterConfig" :filterResult="filterResult" @filterBarResultChange="onFilterBarResultChange"></FilterBarHead>
</template> */
import FilterBarHead from '@/components/comHead/FilterBarHead'
export default {
  components: { FilterBarHead },
  methods: {
    _initOptions(options = []) {
      return options.map((item) => {
        return {
          label: item.label === undefined ? item : item.label,
          value: item.value === undefined ? item + '' : item.value + '', // 统一为字符串类型
        }
      })
    },
    /**
     * 根据 filterConfig初始化默认的筛选结果集
     * @function
     * @param {Array} config - 筛选的配置
     * @param {Object} result - 默认的筛选集
     * @return {Object} 初始化后的筛选集
     */
    initFilterResultByConfig(config, result) {
      config.forEach((cfg) => {
        if (['radio', 'checkgroup'].includes(cfg.type)) {
          cfg.items = this._initOptions(cfg.items)
        }
        if (result[cfg.key] === undefined) {
          if (cfg.type === 'radio') {
            result[cfg.key] = ''
          } else if (cfg.type === 'checkgroup') {
            result[cfg.key] = []
          } else {
            result[cfg.key] = ''
          }
        }
      })
      return result
    },
  },
}
