<script>
/**
 * 该表格基本的能力
 */
import { mapState } from 'vuex'
import Pagination from '@/components/xa-table/Pagination'
import TableToolBar from '@/components/xa-table/ToolBar/Index'
import XaTable from '@/components/xa-table/table/Index'

import titleFilterBarHeadMinxin from '@/mixins/titleFilterBarHeadMinxin.js' 
import { cellBtnClickHandler } from '@/mixins/button.js'

import { eventShow, findCanSearchItems } from './util.js'
import { getExportHead } from './dataFactory.js'
import { exportXls, DataLoader } from '@/controllers/exportTable2Xls'
import mAppDownLoadProgress from '@/controllers/AppDownLoadProgress'
/**
 * configPromise: 为传递配置的promise实例
 */
let table_id = 0
export default {
  configPromise: null,
  components: {
    Pagination,
    TableToolBar,
    XaTable
  },
  mixins: [titleFilterBarHeadMinxin],
  provide () { 
    return {
      pageId: 'TABLE_' + table_id++
    }
  },
  props: {
    configPromise: {
      type: Promise
    }
  },
  data () {
    return {
      isLoading: true,
      hasInit: false,
      drawTable: false,
      isActivated: true,
      // 如果有记录上一级页面
      parent: null, // {path, title}
      page: {
        title: '',
        canExportTable: false
      },
      // 分页相关
      pagination: {
        page: 1,
        total: null,
        pageSize: null,
        pageSizes: []
      },
      // 筛选相关
      filterConfig: [],
      filterResult: {},
      // 搜索相关
      searchConfig: {
        values: {}, // 更多搜索的集合
        enabled: 0,
        keyword: '',
        placeholder: '',
        method: 'get'
      },
      // table相关
      tableBtns: [], // 在table上方显示的按钮
      tableHeader: [], // table上的表头
      tableData: [], // 在table数据集合
      fetchCfg: {
        // 获取表格内容的配置
        url: '',
        method: 'get'
      },
      tableSelectionList: [], // table中被选中的列表
      isTableSelection: false, // 表格能否多选
      alertCom: {
        isShow: false,
        comName: '', //'xaform',
        title: ''
      }
    }
  },
  computed: {
    ...mapState(['windowChangeCount']),
    mTableHeader () {
      return this.tableHeader.filter(item => item.VISIBLE)
    }
  },
  watch: {
    // 观察页面dom有没有变化，有变化需要重新绘制下表格
    windowChangeCount () {
      this.toReDrawTable()
    },
    pagination: {
      deep: true,
      handler (val) {
        setTimeout(() => {
          this.recodePagination(val)
        }, 0)
      }
    }
  },
  methods: {
    toReDrawTable () {
      if (this.isActivated) {
        this.drawTable = false
        this.$nextTick(() => {
          this.drawTable = true
        })
      }
    },
    cellBtnClickHandler,
    btnClickFn (btnConfig) {
      if (btnConfig.router && JSON.stringify(btnConfig).length > 10) {
        let batchParams = {}
        if (btnConfig.batch && this.tableSelectionList && btnConfig.params) {
          batchParams = this.$utils.extractParams(
            this.tableSelectionList,
            btnConfig.params
          )
        }
        this.alertOtherCom(
          'xa' + btnConfig.router.name,
          btnConfig.router.query.src,
          btnConfig.router.params,
          batchParams
        )
      } else {
        this.cellBtnClickHandler(btnConfig, {
          ...this.filterResult,
          keyword: this.searchConfig.keyword
        })
      }
    },
    async onTableExport (type) {
      if (this.tableData.length === 0)
        return this.$message({
          message: '没有数据可以导出',
          type: 'info'
        })
      let exportData = this.tableData
      const fileName = this.page.title
      const exportHead = getExportHead(this.mTableHeader, this.$el)
      if (type.toUpperCase() === 'ALL') {
        const { url, method } = this.fetchCfg
        const { total, pageSize } = this.pagination
        const params = {
          ...this.filterResult,
          ...this.searchConfig.values,
          keyword: this.searchConfig.keyword,
          page_index: 1,
          page_size: pageSize
        }
        const mDataLoader = new DataLoader({
          params,
          action: params => {
            return this.$diyAction(url, params, method).then(
              data => data.tableData
            )
          },
          next (results, params, resultList) {
            resultList.push(...results)
            if (results.length !== params.page_size) {
              return false
            }
            params.page_index++
          }
        })
        const notify = this.$notify({
          title: this.$t('开始导出数据'),
          duration: 3000,
          position: 'bottom-left'
        })
        const cancelTask = mAppDownLoadProgress.add(mDataLoader, {
          title: fileName,
          total
        })
        try {
          exportData = await mDataLoader.run()
          notify.close()
          this.$notify.success({
            title: this.$t('导出成功'),
            position: 'bottom-left'
          })
        } catch (error) {
          notify.close()
          cancelTask && cancelTask()
          this.$message({
            message: error.message || error.msg,
            type: 'error'
          })
        }
      }
      if (exportData.length) {
        exportXls(exportData, exportHead, fileName)
      }
    },
    // 列隐藏显示的筛选
    onfilterTableHeadSubmit (list) {
      list.forEach((item, index) => {
        this.tableHeader[index].VISIBLE = item.VISIBLE
      })
      this.toReDrawTable()
    },
    /**
     * 触发搜索事件
     */
    searchBarChange (msg) {
      const { val, action, values } = msg
      if (values) {
        let cValues = this.$utils.copy(values)
        let mValues = {}
        for (let key in cValues) {
          if (cValues[key] !== '') mValues[key] = cValues[key]
        }
        this.searchConfig.values = mValues
      }
      this.searchConfig.keyword = val
      if (action === 'search') {
        this.pagination.page = 1
        this.fetchTableData()
      }
    },
    /**
     * @description 处理pagination的size变化
     */
    handleTableSelectionChange (selectedList) {
      this.tableSelectionList = selectedList
    },
    /**
     * @description 处理pagination的size变化
     */
    handleSizeChange (size) {
      this.pagination.pageSize = size
      this.fetchTableData()
    },
    /**
     * @description 处理pagination的path变化
     */
    handleCurrentChange (page) {
      this.pagination.page = page
      this.fetchTableData()
    },
    // 获取表格数据 原：getNewTable
    async fetchTableData () {
      if (this.isLoading) return
      if (!this.isActivated) return
      this.isLoading = true
      let params = Object.assign(
        {},
        this.searchConfig.values,
        this.filterResult
      )
      params.keyword = this.searchConfig.keyword
      params.page_index = this.pagination.page
      params.page_size = this.pagination.pageSize
      if (this.fetchCfg.url) {
        try {
          const dtData = await this.$diyAction(
            this.fetchCfg.url,
            params,
            this.fetchCfg.method
          )
          if (dtData.pagination) {
            this.pagination.total = parseInt(dtData.pagination.total)
            if (dtData.pagination.page >= 0)
              this.pagination.page = parseInt(dtData.pagination.page)
          }
          this.tableData = dtData.tableData
        } catch (error) {
          this.$message({
            message: error.message || error.msg,
            type: 'error'
          })
          throw error
        } finally {
          setTimeout(() => {
            this.isLoading = false
          }, 0)
        }
      }
    },
    /**
     * @description 当筛选内容发生改变
     */
    async onFilterBarResultChange (filterResult) {
      this.pagination.page = 1
      this.filterResult = filterResult
      await this.fetchTableData()
      this.checkBtnShowStatusByFilter()
    },
    // BTNS里的按钮根据filter后的结果来控制显示 原：eventShowBTNS2FilterBar
    checkBtnShowStatusByFilter () {
      this.tableBtns.forEach(btn => {
        if (btn.show) btn.SHOW = eventShow(btn.show, this.filterResult)
      })
    },
    recodePagination (pagination) {
      this.$utils.setSessionStorage(
        window.location.hash + '_pagination',
        pagination
      )
    },
    /**
     * @description 初始化筛选配置
     */
    initFilterBar (data) {
      // 后端有时候会设置filterResult为[],需要修正为{}
      if (Array.isArray(data.filterResult)) data.filterResult = {}
      this.filterResult = this.initFilterResultByConfig(
        data.filterConfig || [],
        data.filterResult || {}
      )
      this.filterConfig = data.filterConfig || []
    },
    /**
     * @description 初始化pagination
     */
    initBasePaginationInfo ({ pagination = {} }) {
      const session =
        this.$utils.getSessionStorage(window.location.hash + '_pagination') ||
        {}
      this.pagination = {
        page: pagination.page || session.page || 1,
        total: pagination.total,
        pageSize: parseInt(
          session.pageSize ||
            pagination.pageSize ||
            (pagination.pageSizes ? pagination.pageSizes[0] : 50)
        ),
        pageSizes: pagination.pageSizes || [25, 50]
      }
    },
    /**
     * 获取页面标题 page:{title}
     */
    initBasePageInfo ({ page = {} }) {
      if (this.page.title === '') {
        this.page.title = page.title || this.$route.query.title
      }
      this.page.canExportTable =
        page.canExportTable || Boolean(process.env.VUE_APP_CAN_TABLE_EXPORT)
    },
    /**
     * @description 初始化表格顶部的按钮
     */
    initTableBtns ({ BTNS = [] }) {
      BTNS.forEach(btn => {
        btn.SHOW = true
        if (btn.batch) this.isTableSelection = true
      })
      this.tableBtns = BTNS
      BTNS.length && this.checkBtnShowStatusByFilter()
    },
    // 初始化页面配置参数 对页码 数据的一些补充
    initPageConfig (data) {
      return new Promise(resolve => {
        this.initBasePageParent()
        this.initBasePageInfo(data)
        this.initBasePaginationInfo(data)
        this.initFilterBar(data)
        this.initTableBtns(data)
        if (data.searchConfig) {
          /**
           * 搜索的配置方案有
           * enabled 开启搜索
           * placeholder 默认的搜索提示
           * method 配置的获取表格接口数据的请求方法
           */
          this.searchConfig.items = findCanSearchItems(data.tableHeader)
          this.searchConfig.enabled = data.searchConfig.enabled || 0
          this.searchConfig.placeholder = data.searchConfig.placeholder || ''
          this.fetchCfg.method = data.searchConfig.method || 'get' //
        }
        this.fetchCfg.url = data.tableDataUrl
        data.tableHeader.forEach(item => {
          if (item.VISIBLE === undefined) item.VISIBLE = true
        })
        this.tableHeader = data.tableHeader
        resolve(data)
      })
    },
    initBasePageParent () {
      // formRoute 是从DataTableRoot里传递过来的 {path,title}
      // 当有formRoute时，说明是从一个非菜单的页面(nav!=1)的页面跳进来
      if (this.$options.fromRoute) {
        this.parent = this.$options.fromRoute
        this.$utils.setSessionStorage(
          window.location.hash.replace(/\W/g, '') + '_parent',
          this.parent
        )
      } else {
        const parent = this.$utils.getSessionStorage(
          window.location.hash.replace(/\W/g, '') + '_parent',
          this.parent
        )
        parent && parent.path && (this.parent = parent)
      }
    },
    /**
     * @description 获取页面配置信息
     */
    getViewConfig () {
      this.isLoading = true
      const configPromise =
        this.configPromise ||
        this.$options.configPromise ||
        Promise.reject({ message: 'Datatable没有配置configPromise' })
      return configPromise
        .catch(error => {
          this.$message({
            message: error.message || error.msg,
            type: 'error'
          })
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    async init () {
      const config = await this.getViewConfig()
      if (config) {
        await this.initPageConfig(config)
        await this.fetchTableData()
      }
      this.hasInit = true
      this.drawTable = true
    }
  },
  mounted () {
    this.init()
  },
  activated () {
    this.isActivated = true
  },
  deactivated () {
    this.isActivated = false
  }
}
</script>
