<template>
  <div class="panel-header xa-cell xa-line__bottom">
    <div class="xa-flex">
      <slot name="header">{{ title }}</slot>
    </div>
    <div class="xa-cell">
      <i
        v-for="icon in icons"
        :key="icon"
        :class="icon"
        class="icon-btn iconfont"
        @click="$emit('icon', icon)"
      ></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    icons: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.panel-header {
  padding: 0 8px;
  height: 40px;
  font-size: 14px;
}
.icon-btn {
  display: block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: lighten($color-hover--dark, 20%);
    color: #fff;
  }
  & + & {
    margin-left: 8px;
  }
}
</style>
