import XLSX from 'xlsx'
export default function(name, body, header, cols = []) {
  name = name || '导出记录' + new Date().getTime()
  try {
    var workBook = {
      SheetNames: [name],
      Sheets: {},
      Props: {}
    }
    workBook.Sheets[name] = XLSX.utils.json_to_sheet(body, {
      header
    })
    workBook.Sheets[name]['!cols'] = cols
    return XLSX.writeFile(workBook, name + '.xlsx')
  } catch (error) {
    window.console.log(error)
  }
}
