<template>
  <div class="wrap xa-cell">
    <input
      type="input"
      @keyup.enter="customSearchEvent('search')"
      v-model="seacrchConfig.searchVal"
      class="search-input"
      :style="{ width: width }"
      :placeholder="placeholder"
    />
    <i
      @click.stop="searchInputFn"
      class="serach-icon iconfont icon-sousuo"
      :class="
        !!seacrchConfig.searchVal ? 'xa-txt-blue' : 'serach-icon--transparent'
      "
    ></i>
    <slot name="append"></slot>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// emit('search-bar',{action:input/search,val:val})
export default {
  data() {
    return {
      isShow: '',
      seacrchConfig: {
        isShow: false,
        isFocusing: false,
        searchVal: ''
      }
    }
  },
  props: {
    width: {},
    placeholder: {
      type: String,
      default() {
        return this.$t('EnterSearch')
      }
    }
  },
  watch: {
    'seacrchConfig.searchVal'(val, oldVal) {
      this.$emit('search-bar', {
        action: 'input',
        val: val
      })
    }
  },
  methods: {
    customSearchEvent(type) {
      this.$emit('search-bar', {
        action: 'search',
        val: this.seacrchConfig.searchVal
      })
    },
    /** 搜索 点击 "取消" */
    searchCancelFn() {
      this.seacrchConfig.isFocusing = false
      this.searchVal = ''
    },
    searchInputFn() {
      // 点击搜索
      this.$emit('search-bar', {
        action: 'search',
        val: this.seacrchConfig.searchVal
      })
    }
  }
}
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  border: 1px solid rgb(191, 203, 217);
  border-radius: 4px;
  &:hover {
    border: 1px solid $color-primary;
  }
}

.serach-icon {
  padding: 0 8px 0 4px;
  font-size: 18px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &--transparent {
    color: transparent;
  }
}

.search-input {
  width: 100%;
  height: 30px;
  line-height: 30px !important;
  font-size: 12px;
  padding: 0 0 0 8px;
  box-sizing: border-box;
  border: none;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  &:focus {
    outline: 0 !important;
  }
}
</style>
