<template>
  <section class="filterbar-wrap">
    <div v-if="title" class="filterbar-title xa-cell">
      <div class="xa-cell">
        <el-breadcrumb>
          <template v-if="breadcrumbs && breadcrumbs.length">
            <el-breadcrumb-item
              class="link"
              v-for="item in breadcrumbs"
              :to="item.path"
              :key="item.path"
              >{{ item.title }}</el-breadcrumb-item
            >
          </template>
          <el-breadcrumb-item> {{ title }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="xa-mgl-16" v-if="showSwitchBtn">
          <el-button
            size="mini"
            type="primary"
            plain
            :icon="showFilterContent ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            @click="onShowContent"
          />
        </div>
      </div>
      <div class="xa-mgl-16">
        <slot name="title-fr"> </slot>
      </div>
    </div>
    <div v-show="showFilterContent" class="xa-cell filterbar-content">
      <div class="xa-flex">
        <proxyFilterBar
          v-if="filterConfig.length"
          :filterConfig="filterConfig"
          :filterResult="filterResult"
          @filterBarResult="$emit('filterBarResultChange', $event)"
        />
      </div>
      <div style="align-self: flex-end;">
        <slot name="right-end"> </slot>
      </div>
      <div style="align-self: flex-start;">
        <slot name="filter-right"> </slot>
      </div>
    </div>
  </section>
</template>
<script>
import proxyFilterBar from '@/pk/proxy-filter-bar/Index.vue'
let showFilterContent = true
export default {
  name: 'FilterBarHead',
  components: { proxyFilterBar },
  data() {
    return {
      showFilterContent
    }
  },
  props: {
    title: String,
    parent: {
      type: [Object, Array],
      default() {
        return null
      }
    },
    filterConfig: {
      type: Array,
      default() {
        return []
      }
    },
    filterResult: {
      type: Object,
      default() {
        return {}
      }
    },
    showSwitchBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    breadcrumbs() {
      if (this.parent) {
        if (Array.isArray(this.parent)) {
          return this.parent
        } else if (this.parent.title) {
          return [this.parent]
        }
      }
      return null
    }
  },
  methods: {
    onShowContent() {
      showFilterContent = this.showFilterContent = !this.showFilterContent
      this.$emit('show')
    }
  }
}
</script>
<style scoped lang="scss">
.filterbar-wrap {
  flex-shrink: 0;
}
.filterbar-title {
  height: 40px;
  justify-content: space-between;
}
.filterbar-content {
  position: relative;
  z-index: 99;
  flex-wrap: wrap;
}
</style>
