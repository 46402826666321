import export2Xls from '@/utils/exportXls'
import Event from '@/controllers/Event'
import { MessageBox } from 'element-ui'

/**
 * 导出数据
 * @param {Object[]} tableData - 数据集合
 * @param {Object[]} tableHeader - 导出表格头信息
 * @param {String} fileName - 导出的文件名
 */
export function exportXls(tableData, tableHeader, fileName) {
  const body = tableData.map(item => {
    return tableHeader.reduce((total, head) => {
      total[head.label] = Array.isArray(item[head.key])
        ? item[head.key].join()
        : item[head.key]
      return total
    }, {})
  })
  const cols = tableHeader.map(item => item.col)
  export2Xls(
    fileName,
    body,
    tableHeader.map(item => item.label),
    cols
  )
}

/**
 * 专们负责加载数据的类
 * 负责循环获取所有的数据
 * 当获取数据失败，负责重试
 * 当重试多次失败，增加弹窗提示
 */
export class DataLoader extends Event {
  constructor(
    { action, next, params, confirm },
    { interval = 16 * 4, tryTime = 3 } = {}
  ) {
    super()
    this._action = action
    this._next = next
    this._interval = interval
    this._tryTime = tryTime
    this._curTry = 0
    this._params = { ...params }
    this._result = []
    this._getConfirm =
      confirm ||
      function(error) {
        return '下载数据异常，是否继续？' + JSON.stringify(error)
      }
    this._initEvent()
  }
  run() {
    return new Promise((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
      this._load()
    })
  }
  _confirm(error) {
    return MessageBox.confirm(this._getConfirm(error), '提示', {
      confirmButtonText: '继续下载',
      cancelButtonText: '取消下载'
    })
  }

  _initEvent() {
    this.on('_loadOnce:Ok', data => {
      const canGo = this._next(data, this._params, this._result)
      if (canGo !== false) {
        setTimeout(() => {
          this.emit('load', this._result.length)
          this._load()
        }, this.interval)
      } else {
        this.emit('finish', this._result.length)
        this._resolve(this._result)
      }
    })
    this.on('_loadOnce:Error', error => {
      this._curTry++
      if (this._curTry === this._tryTime) {
        this._confirm(error)
          .then(() => {
            this._curTry = 0
            this._load()
          })
          .catch(() => {
            window.console.log('_loadOnce:Error', '取消下载')
            this._reject({message: '取消下载'})
          })
      }else {
        this._load()
      }
    })
  }
  _load() {
    this._action(this._params)
      .then(data => {
        this.emit('_loadOnce:Ok', data)
      })
      .catch(error => {
        this.emit('_loadOnce:Error', error)
      })
  }
}
