/**
 * 为对象 variables 根据属性名<key>,动作<action>设置相应的值<val>
 * 改variable要先有对应的<kay>,才能设置
 * @function
 * @param {Object<key,action,val>} param0
 * @param {Object} variables
 */
export function xaformEvent({ key, action, val }, variables = {}) {
  if (variables[key] !== undefined) {
    if (Array.isArray(variables[key])) {
      if (action === 'add') {
        variables[key].push(val)
      } else if (action === 'remove') {
        variables[key].splice(variables[key].indexOf(val), 1)
      } else if (action === 'remove_index') {
        variables[key].splice(val, 1)
      } else if (action === 'toggle') {
        let index = variables[key].indexOf(val)
        if (index > -1) {
          variables[key].splice(index, 1)
        } else {
          variables[key].push(val)
        }
      }
    } else if (typeof variables[key] === 'object') {
      variables[key] = val
    }
  }
  return variables
}
function evilFn(fn) {
  var Fn = Function // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn('return ' + fn)()
}
export function eventShow(showConfig, variables = {}) {
  let result = true
  if (Array.isArray(showConfig.items)) {
    let strFn = showConfig.fn
    let resultObj = {}
    showConfig.items.forEach(item => {
      var controlValue = variables[item.control_id]
      resultObj.key = item.control_id
      // 只要命中一个就为ture
      if (Array.isArray(item.value)) {
        if (Array.isArray(controlValue)) {
          resultObj.result = false
          controlValue.forEach(val => {
            if (item.value.indexOf(val) > -1) {
              resultObj.result = true
            }
          })
        } else {
          resultObj.result = item.value.indexOf(controlValue) > -1
        }
      } else if (item.value === undefined) {
        // 如果没有指明命中谁 只要有合理得值 就为true
        if (Array.isArray(controlValue)) {
          if (controlValue.length > 0) {
            resultObj.result = true
          }
        } else {
          if (controlValue) {
            resultObj.result = true
          } else {
            resultObj.result = false
          }
        }
      } else {
        if (Array.isArray(controlValue)) {
          resultObj.result = false
          controlValue.forEach(val => {
            if (item.value === val) {
              resultObj.result = true
            }
          })
        } else {
          resultObj.result = item.value === controlValue
        }
      }
      if (strFn) {
        strFn = strFn.replace(resultObj.key, resultObj.result)
      }
    })
    if (showConfig.items.length === 0) {
      result = resultObj.result
    } else {
      result = evilFn(strFn)
    }
  } else {
    // 依赖一个控件的情况
    var controlValue = variables[showConfig.control_id]
    if (Array.isArray(showConfig.value)) {
      if (Array.isArray(controlValue)) {
        result = false
        controlValue.forEach(val => {
          if (showConfig.value.indexOf(val) > -1) {
            result = true
          }
        })
      } else {
        result = showConfig.value.indexOf(controlValue) > -1
      }
    } else if (showConfig.value === undefined) {
      if (controlValue.length > 0) {
        result = true
      } else {
        result = false
      }
    } else {
      if (controlValue === showConfig.value) {
        result = true
      } else {
        result = false
      }
    }
  }
  return result
}

// 过滤source中存在的target集合，关键字段是key
export function findCanSearchItems(source) {
  return source.filter(item => (item.SEARCH || item.search) && !!item.key)
}
