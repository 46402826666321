/**
 * 获取表头指定字段的宽度
 * 在表格的渲染表头的函数中，约定添加了class: prefix + label
 * 因此通过该class获取表头的宽度，
 * @param {Object} colCfg
 * @param {Object} colCfg.label - 字段名
 * @param {Object} colCfg.width - 配置的字段宽度
 * @param {Element} dom 表格的Dom元素
 * @param {String = 'xa_table_column_'} prefix - 渲染函数中默认指定的样式前缀
 * @returns
 */
export function getColWidth(colCfg, dom, prefix = 'xa_table_column_') {
  const { label, width } = colCfg
  if (dom) {
    const headEl = dom.getElementsByClassName(prefix + label)
    if (headEl && headEl.length) {
      return {
        wpx: parseInt(getComputedStyle(headEl[0]).width)
      }
    }
  }
  return width === undefined
    ? {
        wch: label.length + 2
      }
    : {
        wpx: width
      }
}

function isBtnsCol(btns) {
  return Array.isArray(btns) && btns.length > 0
}

/**
 * 获取可导出的表头
 * @param {Object[]} headers - 表格配置
 * @param {Element} tableEl - 表格的dom元素
 * @returns {Object[]} headers - 处理后的表格头部信息
 * @returns {String} headers[].key - 表格字段
 * @returns {String} headers[].label - 表格字段名
 * @returns {String} headers[].cols - 导出时，表格的字段样式信息
 */
export function getExportHead(headers, tableEl) {
  let mHeaders = headers.filter(item => (isBtnsCol(item.BTNS) || item.IMGS) !== true) // 不导出按钮、图片
  return mHeaders.map(item => {
    const col = getColWidth(item, tableEl)
    return {
      ...item,
      col
    }
  })
}
