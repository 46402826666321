<template>
  <el-row type="flex" justify="space-between" class="xa-mgt-16">
    <el-button size="mini" @click="$emit('cancel')">{{
      cancelTitle || $t('Cancel')
    }}</el-button>
    <el-button
      type="primary"
      size="mini"
      @click="$emit('confirm')"
      :disabled="disabled"
      >{{ confirmTitle || $t('Confirm') }}</el-button
    >
  </el-row>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    cancelTitle: String,
    confirmTitle: String
  }
}
</script>
