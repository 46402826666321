<template>
  <keep-alive :include="/^Cache/">
    <components :is="currentView" :isChild="true" />
  </keep-alive>
</template>
<script>
import dataTable from '@/components/xa-table/index.vue'
export default {
  name: 'diyDataTable',
  components: {
    Welcome: () => import('@/components/Welcome.vue')
  },
  data() {
    return {
      currentView: 'Welcome',
      isActivated: true
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, oldVal) {
        this.$nextTick(function() {
          if (this.isActivated === false) return
          if (!oldVal || val.query.src !== oldVal.query.src)
            val.query.src && this.changeView(val.query.src, val, oldVal)
        })
      }
    }
  },
  methods: {
    async buildComponents(name, from = {}, to = {}) {
      if (this.$options.components[name]) return
      /**
       * 当来源路由 from是nav=1的，即是由导航栏打开的页面
       * 同时要跳转的页面 to不是nav=1,即不是由导航栏打开的页面
       * 此时要记录formRoute，来记住上一级页面
       * 就是此时由导航栏打开的页面成为父级页面。
       * */
      let fromRoute =
        from.query && from.query.nav && !to.query.nav
          ? (fromRoute = {
              path: from.fullPath,
              title: from.query.title
            })
          : null
      const configPromise = this.$actionByRoute()
      this.$options.components[name] = () =>
        Promise.resolve({
          ...dataTable,
          configPromise,
          fromRoute,
          name
        })
    },
    getRightComName(name, to) {
      name =
        'table' +
        name.replace(/\//g, '_') +
        '_' +
        JSON.stringify(to.query.params)
      if (this.$route.name === 'datatableFresh') {
        name = 'Fresh_' + name
      } else if (to.query.nav) {
        name = 'Cache_' + name
      }

      return name.replace(/\W/g, '')
    },
    async changeView(name, to, from) {
      if (!name) return
      name = this.getRightComName(name, to)
      await this.buildComponents(name, from, to)
      setTimeout(() => {
        this.currentView = name
      }, 0)
    }
  },
  activated() {
    this.isActivated = true
  },
  deactivated() {
    this.isActivated = false
  }
}
</script>
