<template>
  <div class="modal-wrapper" :class="{ isKeepRight: isKeepRight }">
    <div class="modal__wrap" :style="{ '--mainwidth': isKeepRightWidth }">
      <div class="modal__backdrop"></div>
      <div class="modal__main xa-view xa-bg-white">
        <PanelHeader :title="title" :icons="iconBtns" @icon="onIconClick" />
        <div class="modal__content xa-flex xa-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setSessionStorage, getSessionStorage } from '@/utils'
import PanelHeader from './PanelHeader.vue'
let isKeepRight = getSessionStorage('agri-bi_Modal-isKeepRight') === 'true'
let isKeepRightWidth = '414px'
export default {
  components: {
    PanelHeader
  },
  data () {
    return {
      isKeepRight,
      isKeepRightWidth,
      isMouseIn: false
    }
  },
  props: {
    title: String,
    visible: Boolean
  },
  computed: {
    iconBtns () {
      return [this.isKeepRight ? 'icon-fanhui' : 'icon-youbian', 'icon-chacha1']
    }
  },
  methods: {
    onIconClick (icon) {
      if (icon === 'icon-chacha1') {
        this.$emit('update:visible', false)
      } else {
        this.triggleKeepRight()
      }
    },
    recode (key, value) {
      setSessionStorage('agri-bi_Modal-' + key, value)
    },
    triggleKeepRight () {
      isKeepRight = this.isKeepRight = !this.isKeepRight
      this.recode('isKeepRight', isKeepRight)
    }
  }
}
</script>
<style lang="scss">
$main-width: 768px;
$main-width-small: 414px;
@mixin fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal-wrapper {
  .modal__wrap {
    @include fixed();
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // 背部蒙版
  .modal__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.65);
    -webkit-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
  }
  .modal__main {
    z-index: 1;
    width: $main-width;
    max-width: 90vw;
    box-sizing: border-box;
  }
  .modal__content {
    max-height: calc(100vh - 40px * 3);
    padding: 0 8px;
  }
  // 在靠右时候样式
  &.isKeepRight {
    .modal__backdrop {
      display: none;
    }
    .modal__wrap {
      position: relative;
      display: block;
      height: 100vh;
      margin-left: 4px;
      border-left: 4px solid#e4e7ed;
    }
    .modal__main {
      height: 100vh;
      width: $main-width-small;
      width: var(--mainwidth);
    }
    .modal__content {
      max-height: calc(100vh - 40px);
    }
  }
}
</style>
