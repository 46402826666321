<template>
  <el-container class="xa-table__page xa-container" direction="horizontal">
    <el-container class="xa-container" direction="vertical">
      <FilterBarHead
        :title="page.title"
        :parent="parent"
        :filterConfig="filterConfig"
        :filterResult="filterResult"
        @filterBarResultChange="onFilterBarResultChange"
        @show="toReDrawTable"
      >
        <template slot="filter-right">
          <TableToolBar
            v-show="hasInit"
            @export="onTableExport"
            @triggerbtnclick="btnClickFn"
            @triggersearch="searchBarChange"
            @triggerfilter="onfilterTableHeadSubmit"
            :tableSelectionList="tableSelectionList"
            :canExport="page.canExportTable"
            :btns="tableBtns"
            :searchConfig="searchConfig"
            :tableHeader="tableHeader"
          />
        </template>
      </FilterBarHead>
      <el-main class="xa-bg-white xa-flex" style="padding:0;">
        <div v-show="isLoading" style="position:relative;height:0;">
          <AppLineLoading />
        </div>
        <section style="height:100%;width:100%;overflow-x:hidden;" v-show="hasInit">
          <xa-table
            v-if="drawTable"
            @table="onTableEvent"
            @selection-change="handleTableSelectionChange"
            ref="table"
            :tableHeader="mTableHeader"
            :tableData="tableData"
            :selection="isTableSelection"
          />
        </section>
      </el-main>
      <Pagination
        v-if="hasInit"
        :curPage="pagination.page"
        :pageSizes="pagination.pageSizes"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @sizechange="handleSizeChange"
        @curchange="handleCurrentChange"
        @refresh="fetchTableData"
      />
    </el-container>
    <section v-if="alertCom.isShow">
      <Modal :title="alertCom.title" :visible.sync="alertCom.isShow">
        <components
          :is="alertCom.comName"
          @table="onTableEvent"
          @close="alertCom.isShow = false"
          @refresh="fetchTableData"
          @title="alertCom.title = $event"
        />
      </Modal>
    </section>
  </el-container>
</template>
<script>
import Base from './base'
import XaForm from '@/components/xa-form/Index.vue'
import Modal from '@/components/xa-table/panel/PanelBox.vue'
const xaMarkDown = () => import('@/components/XaMarkDown')
const matchComName = {
  form: 'xaform',
  markdown: 'xaMarkDown'
}
export default {
  mixins: [Base],
  components: {
    Modal
  },
  methods: {
    // 目前table可以调用的弹窗的内容暂时只有form
    // eslint-disable-next-line no-unused-vars
    onTableEvent ({ action, router }) {
      if (action === 'alert') {
        const comName = matchComName[router.name]
        if (comName === undefined) {
          this.$alert('在alert模式下，还没有对' + router.name + '的支持', '', {
            type: 'error',
            confirmButtonText: '确定'
          })
          return
        }
        this.alertOtherCom(
          matchComName[router.name],
          router.query.src,
          router.params
        )
      } else if (action === 'refresh') {
        this.fetchTableData()
      }
    },
    // 调用弹窗的方法 目前是diyform
    async alertOtherCom (comName, src, params, variables) {
      if (comName.toUpperCase() === 'XAFORM') {
        const configPromise = this.$diyAction(src, params).then(data => {
          /// 注入表单结果集合variables
          if (variables && Object.keys(variables).length > 0) {
            Object.assign(data.variables, variables)
          }
          return data
        })
        this.$options.components[comName] = () =>
          Promise.resolve({
            ...XaForm,
            configPromise,
            comName
          })
      } else if (comName.toUpperCase() === 'XAMARKDOWN') {
        const content = await this.$diyAction(src || `/bi/JSX.md`, params)
        const com = await xaMarkDown().then(mModule => {
          window.console.log('module', mModule)
          mModule.default.props.content.default = content
          return mModule.default
        })
        this.$options.components[comName] = com
      }
      this.alertCom.isShow = false
      this.alertCom.comName = ''
      this.$nextTick(function () {
        this.alertCom.isShow = true
        this.alertCom.comName = comName
      })
    }
  }
}
</script>
<style lang="scss">
.xa-table__drawer {
  min-width: 480px;
  .el-drawer__header {
    padding: 10px 16px;
    margin: 0;
  }
}
</style>
