import request from '@/apis/index'
function falseFn() {
  return false
}
class Form {
  constructor(config) {
    this.value = config.variable || config.key
    this.title = config.title
    this.placeholder = config.placeholder
    this.size = 'small'
  }
  getDefault() {
    return ''
  }
  isEmptyValue() {
    return false
  }
  toJson() {
    return {
      value: this.value,
      title: this.title,
      placeholder: this.placeholder,
      size: this.size,
      com: this.com
    }
  }
}
// 时间选择
export class Datetime extends Form {
  constructor(config) {
    config.title = config.title || '选择时间'
    super(config)
    this.com = 'el-datepicker'
    this.model = config.model || 'daterange'
    this['range-separator'] = '/'
  }
  getDefault(initValue) {
    return initValue || ''
  }
  isEmptyValue(value) {
    return value === ''
  }
  toJson() {
    let cfg = {
      ...super.toJson(),
      model: this.model
    }
    return Object.freeze(cfg)
  }
}
// 单选/多选
export class Select extends Form {
  constructor(config) {
    super(config)
    this.com = 'el-select'
    if (config.type !== 'radio') {
      this.multiple = true
      this['collapse-tags'] = true
    }
    if (config.cascade && config.cascade.url) {
      const { cascade } = config
      this.filterable = this.remote = true
      this.cascadeKey = cascade.urlKey
      this.cascadeUrl = cascade.url
      this.remoteMethod = function(keywork) {
        window.console.log('remoteMethod', keywork)
      }
    }
    if (config.items && config.items.length) {
      this.options = this._initOptions(config.items)
      this.filterable = this.options.length > 3
    }
    this._cache = {}
  }
  _initOptions(options) {
    return options.map(item => {
      const label = item.label === undefined ? item : item.label
      const value = item.value === undefined ? item + '' : item.value + '' // 统一为字符串类型
      if (['全部', '所有'].includes(label)) {
        this.emptyValue = value // 当有此值时候，认为是没有选
      }
      return {
        label,
        value
      }
    })
  }
  async _fetchOptions(params) {
    const result = await request({
      url: this.cascadeUrl,
      data: {
        [this.cascadeKey]: params
      }
    })
    window.console.log('_fetchOptions', result)
    this._cache[params] = this._initOptions(result)
    this.options = this._cache[params]
  }
  async toFetchOptions(arg, cb) {
    if (this.remote) {
      if (this._cache[arg]) {
        this.options = this._cache[arg]
      } else {
        await this._fetchOptions(arg)
      }
      cb && cb()
    }
  }
  getDefault(initValue) {
    if (initValue !== undefined) {
      if (this.multiple && initValue === '') return []
      return initValue + ''
    }
    return this.multiple ? [] : ''
  }
  isEmptyValue(value) {
    if (this.multiple) {
      return value.length === 0
    } else {
      if (this.emptyValue !== undefined) return this.emptyValue === value
      return value === ''
    }
  }
  toJson() {
    let cfg = {
      ...super.toJson(),
      multiple: this.multiple || false,
      filterable: this.filterable || false,
      options: this.options || [],
      remote: this.remote || false,
      remoteMethod: this.remoteMethod || falseFn,
      'collapse-tags': this['collapse-tags'] || false
    }
    if (cfg.remote) {
      return cfg
    } else {
      return Object.freeze(cfg)
    }
  }
}

export default class {
  constructor(config) {
    const type = config.type.toLocaleLowerCase()
    if (['radio', 'checkgroup'].includes(type)) {
      return new Select(config)
    } else if (['datepicker'].includes(type)) {
      return new Datetime(config)
    }
  }
}
