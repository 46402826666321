<template>
    <div style="line-height:40px;font-size:14px">
        <div style="display:inline-block;margin-right:8px">
            <el-checkbox-group size="small" v-model="value">
                <template v-for="(config,index) in configs">
                    <el-checkbox :key="index" :label="config.value">{{config.label}}</el-checkbox>
                </template>
            </el-checkbox-group>
        </div>
        <el-button @click="emitFilter()" :disabled="showBtn" type="primary" icon="el-icon-search" size="small"></el-button>
    </div>
</template>
<script>
import RadioFilter from './radio-filter.vue'
export default {
    extends: RadioFilter
}
</script>

