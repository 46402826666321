export default class {
    constructor () {
      this.events = {}
    }
  
    on (eventName, fn) {
      const listenter = this.events[eventName] || (this.events[eventName] = [])
      if (listenter.indexOf(fn) === -1) {
        listenter.push(fn)
      }
    }
  
    once (eventName, fn) {
      function wrapFn () {
        fn.apply(this, arguments)
        this.off(eventName, wrapFn)
      }
      this.on(eventName, wrapFn)
    }
  
    off (eventName, fn) {
      const listenter = this.events[eventName]
      if (listenter) {
        const index = listenter.indexOf(fn)
        index > -1 && listenter.splice(index, 1)
      }
    }
  
    clear (eventName) {
      if (eventName) {
        this.events[eventName] = []
      } else {
        this.events = {}
      }
    }
  
    emit (eventName, ...avg) {
      const listenter = this.events[eventName]
      if (listenter) {
        listenter.forEach(fun => {
          fun.apply(this, avg)
        })
      }
    }
  
    destory () {
      this.events = {}
    }
  }
  