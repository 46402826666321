<script>
import PopFilters from './pop-filters.vue'
import RadioFilter from './radio-filter.vue'
import CheckgroupFilter from './checkgroup-filter.vue'
export default {
  functional: true,
  render(h, context) {
    const { props } = context
    const { filterConfig } = props
    function filterBarComponent() {
      const len = filterConfig.length
      if (len === 0) {
        return ''
      } else if (
        len === 1 &&
        filterConfig[0].type === 'radio' &&
        filterConfig[0].items.length <= 8
      ) {
        return RadioFilter
      } else if (
        len === 1 &&
        filterConfig[0].type === 'checkgroup' &&
        filterConfig[0].items.length <= 8
      ) {
        return CheckgroupFilter
      } else {
        return PopFilters
      }
    }
    return h(filterBarComponent(), context.data, context.children)
  }
}
</script>
