<template>
  <div class="xa-cell">
    <SearchBar
      :class="result ? 'fixed-right' : ''"
      v-on:search-bar="$emit('submit', $event)"
    >
      <template slot="append" v-if="result">
        <div
          v-popover:popover
          plain
          class="pop-btn xa-txt-blue xa-cell xa-cursor"
        >
          <span>更多</span>
        </div>
      </template>
    </SearchBar>
    <div v-if="result">
      <el-popover
        ref="popover"
        placement="bottom"
        width="320"
        v-model="dialogVisible"
      >
        <div
          class="xa-cell"
          style="margin:4px 0"
          v-for="item in items"
          :key="item.key"
        >
          <div
            class="filter-label xa-txt-12 xa-txt-secondary"
            style="margin-right:8px"
          >
            {{ item.label }}
          </div>
          <el-input
            placeholder="请输入内容"
            v-model="result[item.key]"
            size="small"
            clearable
          />
        </div>
        <DialogFootBtn
          @cancel="onCancel"
          @confirm="onConfirm"
          :disabled="!isChange"
        />
      </el-popover>
    </div>
  </div>
</template>
<script>
import SearchBar from './SearchBar.vue'
import DialogFootBtn from '@/components/DialogFootBtn.vue'
export default {
  components: {
    SearchBar,
    DialogFootBtn
  },
  inject: ['pageId'],
  data() {
    return {
      dialogVisible: false,
      result: null,
      lastConfirmResult: null
    }
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return this.pageId
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    isChange() {
      return this.lastConfirmResult !== JSON.stringify(this.result)
    }
  },
  methods: {
    onCancel() {
      this.dialogVisible = false
    },
    onConfirm() {
      this.$emit('submit', {
        action: 'search',
        values: this.result
      })
      this.lastConfirmResult = JSON.stringify(this.result)
    }
  },
  created() {
    if (!this.items.length) return
    const result = {}
    this.items.forEach(item => {
      result[item.key] = ''
    })
    this.result = result
    this.lastConfirmResult = JSON.stringify(result)
  }
}
</script>
<style lang="scss" scoped>
.filter-label {
  flex-shrink: 0;
  width: 7em;
}
.fixed-right /deep/ .search-input {
  border-right: none;
}
.pop-btn {
  line-height: 30px;
  white-space: nowrap;
  font-size: 12px;
  padding: 0 8px;
  border-left: 1px solid rgb(191, 203, 217);
  &:focus {
    outline: none !important;
    // border: none;
  }
}
</style>
