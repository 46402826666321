<template>
  <div class="xa-cell">
    <div class="xa-cell" v-if="displayStickItem">
      <template v-for="(item, index) in configs">
        <div
          v-if="stickyKeys.has(item.value)"
          class="xa-cell xa-mgr-16"
          :key="index + item.value"
        >
          <div class="xa-txt-12 xa-txt-secondary" style="margin-right:8px">
            {{ item.title }}
          </div>
          <DateTime
            class="display-item display-item__datepicker"
            v-if="item.com === 'el-datepicker'"
            v-bind="item"
            v-model="result[item.value]"
          />
          <el-select
            class="display-item display-item__select"
            v-if="item.com === 'el-select'"
            v-bind="item"
            v-model="result[item.value]"
            :loading="isLoadingMap[item.value]"
          >
            <el-option
              v-for="item in item.options"
              :key="item.value + item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </template>
    </div>
    <template v-if="filterConfig.length > 1">
      <el-popover
        ref="popover"
        placement="bottom"
        width="320"
        v-model="dialogVisible"
      >
        <div
          class="xa-cell"
          style="margin:4px 0"
          v-for="(item, index) in configs"
          :key="index + item.value"
        >
          <div class="filter-label xa-txt-secondary">
            {{ item.title }}
          </div>
          <DateTime
            style="width:215px;"
            v-if="item.com === 'el-datepicker'"
            v-bind="item"
            v-model="result[item.value]"
          />
          <el-select
            style="width:215px;"
            v-if="item.com === 'el-select'"
            v-bind="item"
            v-model="result[item.value]"
            :loading="isLoadingMap[item.value]"
          >
            <el-option
              v-for="item in item.options"
              :key="item.value + item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <FootBtn
          @cancel="onCancel"
          @confirm="onConfirm"
          :disabled="!isChange"
        />
      </el-popover>
      <el-button
        v-popover:popover
        type="primary"
        size="small"
        plain
        class="filter-btn xa-cell"
      >
        <i class="el-icon-s-operation"></i
        ><span class="filter-btn__label">筛选数据</span>
      </el-button>
    </template>
  </div>
</template>
<script>
import DateTime from './date-time.vue'
import FootBtn from './foot-btn.vue'
import Select from './Select'
export default {
  components: {
    DateTime,
    FootBtn
  },
  data () {
    return {
      configs: null, // 筛选配置
      stickyKeys: {}, // 需要置顶的筛选项
      result: null, // 筛选结果
      selectorList: null, // 筛选类，分别有其具体逻辑
      oldResult: null, // 记录上一次结果
      isLoadingMap: {}, // 标记是不是正在请求
      needWatchValue: null, // 需要观察的值
      lastConfirmResult: null, // 上一次确认提交的结果
      dialogVisible: false,
      needToStick: false, // 是不是需要更新置顶项目
      neddToUpdateStickItem: false,
      displayStickItem: true
    }
  },
  computed: {
    isChange () {
      return this.lastConfirmResult !== JSON.stringify(this.result)
    }
  },
  watch: {
    dialogVisible (visible) {
      // 在关闭时候，需要更新置顶项
      if (visible === false && this.needToStick) {
        this.handleStickyFilterItems()
      }
    },
    result: {
      deep: true,
      handler (nVal) {
        // 知道哪些筛选值发生了改变
        let changeValues = []
        if (this.oldResult) {
          // 非第一次值改变
          for (let key in nVal) {
            if (nVal[key] !== this.oldResult[key]) changeValues.push(key)
          }
        } else {
          // 第一次初始化
          changeValues = Object.keys(nVal)
        }
        changeValues = changeValues.filter(key =>
          this.needWatchValue.includes(key)
        )
        if (changeValues.length) {
          setTimeout(() => {
            this.selectToUpdate(changeValues)
          }, 0)
        }
        this.oldResult = this.$utils.copy(nVal)
        if (this.dialogVisible === false) {
          this.toSubmit()
        }
      }
    }
  },
  props: {
    filterConfig: {
      type: Array,
      default () {
        return []
      }
    },
    filterResult: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    updateDisplayStickItem () {
      this.displayStickItem = false
      this.$nextTick(() => {
        this.displayStickItem = true
      })
    },
    /**
     * 处理置顶的筛选项
     * 1. 优先置顶有值的
     * 2. 最少置顶一个
     */
    handleStickyFilterItems () {
      const result = JSON.parse(this.lastConfirmResult)
      const stickyKeys = new Set()
      let count = 0
      this.selectorList.forEach(selector => {
        if (!selector.isEmptyValue(result[selector.value])) {
          stickyKeys.add(selector.value)
          count++
        }
      })
      if (count === 0) {
        stickyKeys.add(this.selectorList[0].value)
      }
      this.stickyKeys = stickyKeys
      if (this.neddToUpdateStickItem) this.updateDisplayStickItem()
    },
    onCancel () {
      this.dialogVisible = false
      if (JSON.stringify(this.result) !== this.lastConfirmResult) {
        this.result = JSON.parse(this.lastConfirmResult)
      }
    },
    onConfirm () {
      if (this.result) {
        this.toSubmit()
        this.needToStick = true
        this.dialogVisible = false
      }
    },
    toSubmit () {
      this.lastConfirmResult = JSON.stringify(this.result)
      this.$emit('filterBarResult', this.$utils.copy(this.result))
    },
    selectToUpdate (changeList) {
      const remoteSelectItems = this.selectorList.filter(item => item.remote)
      if (remoteSelectItems.length) {
        changeList.forEach(key => {
          const selects = remoteSelectItems.filter(
            select => select.cascadeKey === key
          )
          selects.forEach(item => {
            this.isLoadingMap[item.value] = true
            this.result[item.value] = item.getDefault()
            item.toFetchOptions(this.result[item.cascadeKey], () => {
              this.selectToUpdateCfg(item.value)
            })
          })
        })
      }
    },
    selectToUpdateCfg (key) {
      this.isLoadingMap[key] = false
      this.configs = this.selectorList.map(item => item.toJson())
    }
  },
  mounted () {
    const needWatchValue = new Set() // 需要观察的字段
    const isLoadingMap = {} // 需要动态更新
    const variables = {}
    const selectorList = this.filterConfig
      .map(item => {
        const select = new Select(item)
        variables[select.value] = select.getDefault(
          this.filterResult[select.value]
        )
        if (select.remote) {
          isLoadingMap[select.value] = select.remote
          needWatchValue.add(select.cascadeKey)
        }
        if (select.com === 'el-select') this.neddToUpdateStickItem = true
        return select
      })
      .filter(item => !!item)
    this.selectorList = Object.freeze(selectorList)
    this.result = variables
    this.lastConfirmResult = JSON.stringify(this.result)
    this.needWatchValue = Object.freeze([...needWatchValue])
    this.configs = this.selectorList.map(item => item.toJson())
    this.handleStickyFilterItems()
  }
}
</script>
<style lang="scss" scoped>
.filter-label {
  width: 7em;
  font-size: 12px;
}
/deep/.filter-btn {
  height: 30px;
}
/deep/.filter-btn__label {
  display: inline-block;
  margin-left: 8px;
}
.display-item {
  &__select {
    width: 180px;
  }
  &__datepicker {
    width: 200px;
  }
  /deep/ .el-range-input {
    font-size: 10px;
  }
  /deep/ .el-input__inner {
    padding-left: 4px;
    font-size: 10px;
  }
}
</style>
