<template>
  <div style="line-height:40px;font-size:14px">
    <el-radio-group size="small" v-model="value" style="margin-right:8px">
      <template v-for="(config, index) in configs">
        <el-radio-button :key="index" :label="config.value">{{
          config.label
        }}</el-radio-button>
      </template>
    </el-radio-group>
    <el-button
      @click="emitFilter()"
      :disabled="showBtn"
      type="primary"
      icon="el-icon-search"
      size="small"
    ></el-button>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
export default {
  data() {
    return {
      value: '',
      curKey: '',
      label: '',
      configs: [],
      showEmitBtn: false,
      variables: ''
    }
  },
  props: {
    filterConfig: {
      type: Array
    },
    filterResult: {
      type: Object
    }
  },
  watch: {
    filterResult: {
      deep: true,
      handler(val, oldVal) {
        this.variables = this.filterResult[this.curKey]
      }
    }
  },
  computed: {
    showBtn() {
      if (Array.isArray(this.value)) {
        return JSON.stringify(this.value) === JSON.stringify(this.variables)
      } else {
        return this.value === this.variables
      }
    }
  },
  methods: {
    emitFilter() {
      let filterResult = JSON.parse(JSON.stringify(this.filterResult))
      filterResult[this.curKey] = this.value
      this.$emit('filterBarResult', filterResult)
    }
  },
  mounted() {
    if (this.filterConfig && this.filterConfig.length === 1) {
      let config = this.filterConfig[0]
      this.curKey = config.key
      this.label = config.title
      this.configs = config.items
      this.variables = this.filterResult[config.key]
      this.value = this.variables
    }
  }
}
</script>
