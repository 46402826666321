<template>
  <div class="tool-bar__wrap xa-cell">
    <el-button
      v-for="(btn, index) in btns"
      :key="index"
      v-show="btn.SHOW"
      :disabled="btn.batch && tableSelectionList.length == 0"
      type="primary"
      class="xa-mgl-8"
      @click.native="btnClickFn(btn)"
    >
      {{ btn.label }}
    </el-button>
    <div class="xa-cell xa-mgl-8" v-if="searchConfig.enabled">
      <SearchBar :items="searchConfig.items" @submit="onSearchSubmit" />
    </div>
    <!-- 导出按钮 -->
    <el-popover v-if="canExport" placement="top" trigger="hover">
      <div>
        <div class="xa-txt-12 xa-pdb-8">
          {{ $t('ExportDataRange') }}
        </div>
        <el-button
          type="primary"
          size="mini"
          plain
          @click="$emit('export', 'one')"
          >{{ $t('CurrentPageData') }}</el-button
        >
        <el-button
          type="primary"
          size="mini"
          plain
          @click="$emit('export', 'all')"
          >{{ $t('ConformanceConditionData') }}</el-button
        >
      </div>
      <el-button
        class="xa-mgl-8"
        type="primary"
        icon="el-icon-download"
        plain
        slot="reference"
      />
    </el-popover>
    <!--列展示筛选-->
    <filterhead-Btn :filterList="tableHeader" @onConfirm="onfilterBtnConfirm" />
  </div>
</template>
<script>
import SearchBar from './PopSearch.vue'
import FilterheadBtn from './FilterheadBtn.vue'
export default {
  components: {
    SearchBar,
    FilterheadBtn
  },
  props: {
    tableHeader: {
      type: Array,
      default() {
        return []
      }
    },
    btns: {
      type: Array,
      default() {
        return []
      }
    },
    // 是否可以导出页面数据
    canExport: {
      type: Boolean,
      default: false
    },
    searchConfig: {
      type: Object,
      default() {
        return {
          items: [],
          enabled: 0,
          keyword: ''
        }
      }
    },
    // table在选择列的模式下，选中的列
    tableSelectionList: {
      type: Array,
      default() {
        return []
      }
    },
    editConfig: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onSearchSubmit(msg) {
      this.$emit('triggersearch', msg)
    },
    onfilterBtnConfirm(list) {
      this.$emit('triggerfilter', list)
    },
    btnClickFn(config) {
      this.$emit('triggerbtnclick', config)
    }
  }
}
</script>
<style lang="scss">
.tool-bar__wrap {
  padding: 5px 0px;
  .tool-bar__searchbar {
    width: 200px;
  }
  .el-button {
    box-sizing: border-box;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 30px;
  }
}
</style>
