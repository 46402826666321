<template>
  <el-tooltip :content="$t('TableVisibility')" placement="top">
    <div class="xa-mgl-8">
      <!--列展示筛选-->
      <el-popover
        ref="popover5"
        placement="bottom"
        width="200"
        v-model="isShow"
      >
        <div class="head-item" v-for="(hItem, index) in list" :key="index">
          <label class="xa-cell">
            <i
              class="iconfont xa-mgr-8"
              :class="{
                'icon-yanjing icon--visible': hItem.VISIBLE,
                'icon-yanjing2': !hItem.VISIBLE
              }"
            ></i>
            <input
              style="opacity: 0;visibility: hidden;width: 0;height: 0;"
              type="checkbox"
              v-model="hItem.VISIBLE"
            />
            <span class="xa-txt-ellipsis">{{ hItem.label }}</span>
          </label>
        </div>
        <DialogFootBtn
          @cancel="isShow = false"
          @confirm="onConfirm"
          :disabled="isValid"
        />
      </el-popover>
      <el-button v-popover:popover5 type="primary" plain icon="el-icon-setting">
      </el-button>
      <!--列展示筛选-->
    </div>
  </el-tooltip>
</template>
<script>
import DialogFootBtn from '@/components/DialogFootBtn.vue'
export default {
  components: {
    DialogFootBtn
  },
  data() {
    return {
      isShow: false,
      list: []
    }
  },
  props: {
    filterList: {},
    className: {}
  },
  watch: {
    filterList(val) {
      this.list = JSON.parse(JSON.stringify(val))
    },
    isShow() {
      this.list = JSON.parse(JSON.stringify(this.filterList))
    }
  },
  computed: {
    isValid() {
      var filterList = this.list.filter(item => {
        return item.VISIBLE
      })
      return filterList.length < 2
    }
  },
  methods: {
    onConfirm() {
      this.$emit('onConfirm', this.list)
      this.isShow = !this.isShow
    }
  }
}
</script>
<style scoped>
.head-item {
  font-size: 12px;
  height: 16px;
  margin: 4px 0;
  line-height: 16px;
}

.head-item input {
  vertical-align: middle;
  margin-top: -2px;
  margin-bottom: 1px;
}

.head-item label:hover {
  color: #20a0ff;
  cursor: pointer;
}

.head-item .icon--visible {
  color: #20a0ff;
}
</style>
