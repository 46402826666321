/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// 用在 src/views/dataTable.vue
// dataTable：BTNS
// "BTNS": [
//     {
//         "label": "新建",
//         "router": {
//             "name": "form",
//             "query": {
//                 "src": "/main/protection/home/edit_page"
//             }
//         }
//     }
// ],

// 用在 src/components/table/table.vue
// dataTbale:tableHeader=>[]=>BTNS
//  "BTNS": [
//             {
//                 "label": <>String>"按钮文字",
//                 "confirm": <String>"操作提醒",
//                 "action": [ajax|alert|link|link#new]"一步操作|弹窗|跳转链接|跳转链接(用一个新的tab)",
//                 [action=ajax]&&"batch": <Boolean>"是否是批量操作",
//                 "type": [success|warning|danger|icon]"按钮的颜色主题",
//                 [action=ajax]&&"api":<String>"请求地址",
//                 "params":<Array> <String>[key] "ajax时候带给后后端的参数列表|alert时候传递给其他组件的参数列表|link,link#new跳转链接附带的参数列表"
//             },
//             {
//                 "label": "编辑",
//                 "action": "alert",
//                 "type": "info",
//                 "router": {
//                     "name": "datatable",
//                     "query": {
//                         "src": "diyform_admin.json",
//                         "page": "4"
//                     }
//                 },
//                 "params": [
//                     "name"
//                 ]
//             }
//         ]

function extractParams(rowDatas, paramKeys) {
  let params = {}
  rowDatas.forEach(data => {
    paramKeys.forEach(key => {
      if (params[key] === undefined) {
        params[key] = []
      }
      if (data[key] !== undefined) {
        params[key].push(data[key])
      }
    })
  })
  return params
}

// 用在 src/components/table/table.vue 以及 用在 src/views/dataTable.vue
//  btnConfig = {
//                 "label": <>String>"按钮文字",
//                 "confirm": <String>"操作提醒",
//                 "action": [ajax|alert|link|link#new]"一步操作|弹窗|跳转链接|跳转链接(用一个新的tab)",
//                 [action=ajax]&&"batch": <Boolean>"是否是批量操作",
//                 "type": [success|warning|danger|icon]"按钮的颜色主题",
//                 [action=ajax]&&"api":<String>"请求地址",
//                 "params":<Array> <String>[key] "ajax时候带给后后端的参数列表|alert时候传递给其他组件的参数列表|link,link#new跳转链接附带的参数列表"
//             }
let cellBtnClickHandler = function(btnConfig, rowData) {
  let params = {}
  if (rowData !== undefined) {
    if (btnConfig.params) {
      // 从rowData里面提取变量
      btnConfig.params.forEach(param => (params[param] = rowData[param]))
    } else {
      btnConfig.params = []
    }
  }
  if (btnConfig.action === 'ajax') {
    if (btnConfig.confirm) {
      let tableSelectionList = this.tableSelectionList
      if (
        tableSelectionList === undefined ||
        !Array.isArray(tableSelectionList)
      ) {
        btnClickFn.batch = false
      }
      this.$msgbox({
        title: '消息',
        message:
          btnConfig.confirm +
          (btnConfig.batch
            ? '(选择了' + tableSelectionList.length + '项)'
            : ''),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            let batchParams = {}
            if (btnConfig.batch && tableSelectionList && btnConfig.params) {
              batchParams = extractParams(tableSelectionList, btnConfig.params)
            }
            this.$diyAction(
              btnConfig.api,
              Object.assign(params, batchParams),
              'post'
            )
              .then(data => {
                setTimeout(() => {
                  this.$emit('table', {
                    action: 'refresh'
                  })
                }, 100)
              })
              .catch(error => {
                this.$message({
                  type: 'error',
                  message: error.message
                })
              })
              .finally(() => {
                done()
              })
          } else {
            done()
          }
        },
        callback: (action, instance) => {
          instance.confirmButtonLoading = false
          instance.confirmButtonText = '确定'
        }
      }).then(action => {
        this.$message({
          type: 'info',
          message: '操作成功！'
        })
      })
    }
  } else if (btnConfig.action === 'alert') {
    // 构想中 还没有用到
    if (!btnConfig.router.params) {
      btnConfig.router.params = {}
    }
    Object.assign(btnConfig.router.params, params)
    // 目前不会用到
    this.$emit('table', {
      action: 'alert',
      router: btnConfig.router,
      config: {}
    })
  } else if (btnConfig.action === 'link' || btnConfig.action === 'link#new') {
    if (btnConfig.link) {
      let mParams = JSON.stringify(params)
      mParams =
        mParams.length > 2
          ? (btnConfig.link.indexOf('?') > -1 ? '&' : '?') +
            'params=' +
            window.encodeURIComponent(mParams)
          : ''
      this.$gotoUrl(btnConfig.link + mParams, btnConfig.action === 'link#new')
    }
  }
}
// 用在 src/views/dataTable.vue
let btnClickFn = function(btnConfig) {
  console.info('mixins:btnClickFn')
  // 把一些指定的组件已弹窗的形式呈现出来
  if (btnConfig.router && JSON.stringify(btnConfig).length > 10) {
    let batchParams = {}
    if (btnConfig.batch && this.tableSelectionList && btnConfig.params) {
      batchParams = extractParams(this.tableSelectionList, btnConfig.params)
    }
    this.alertOtherCom(
      'xa' + btnConfig.router.name,
      btnConfig.router.query.src,
      btnConfig.router.params,
      batchParams
    )
  } else {
    if (cellBtnClickHandler !== undefined) {
      cellBtnClickHandler.call(this, btnConfig, {
        ...this.filterResult,
        keyword: this.searchConfig.keyword
      })
    }
  }
}

export { btnClickFn, cellBtnClickHandler }
